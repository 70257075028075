<template>
  <div class="main">
    <div class="banner">
      <Carousel
        :autoplay="false"
        dots="none"
        arrow="never"
        v-model="bannerIndex" loop>
        <CarouselItem>
          <div class="banner-item">
            <img src="../../assets/image/banner_about.png" alt="">
            <span>关于众博</span>
          </div>
        </CarouselItem>
      </Carousel>
    </div>
    <div class="introduce-content">
      <p class="content-title">公司简介</p>
      <div class="introduce-item">
        <div class="item-label">公司名称</div>
        <div class="item-text">众博信息技术股份有限公司</div>
      </div>
      <div class="introduce-item">
        <div class="item-label">公司位置</div>
        <div class="item-text">沧州市颐和大厦1905</div>
      </div>
      <div class="introduce-item">
        <div class="item-label">成立时间</div>
        <div class="item-text">2014年</div>
      </div>
      <div class="introduce-item">
        <div class="item-label">公司业务</div>
        <div class="item-text">阿里巴巴代运营、零售通代运营、产品拍摄、旺铺装修、外贸官网、软件开发</div>
      </div>
      <div class="introduce-item" style="border-width: 0px">
        <div class="item-label">公司介绍</div>
        <div class="item-text">
          <p>公司成立与于2014年,是一家专业的电商代理运营工作室，2014年年初正式注册“沧州市众博信息技术有限公司”公司化运营。 开始使用“众博信息”作为公司代名词。</p>
          <p>众博信息技术股份有限公司主要业务包括：阿里巴巴国际站、国内站的装修、代运营，企业网站的设计、开发、运营、优化、推广，企业微信公众平台建设等。团队骨干有着丰富的旺铺装修、代运营经验以及网页设计和网站开发水平，为客户提供更符合搜索引擎的营销方式，提供阿里巴巴、企业网站、微网站、微商城、微信裂变式分销，国内外虚拟主机、网站维护、网络推广等互联网一站式服务。我们将商业与技术完美结合起来，以使我们的客户可以在快速发展的信息科技领域中获得更有效的竞争力。</p>
          <p>公司成立以来，先后为沧州永佳通讯设备、恒建体育用品、鑫骏成管道、恒基钻头、岚康医用开发、天祥进出口贸易、诺亚机械制造、骏泰机械制造、洪升包装机械设备、任丘新川链轮等数百家企业提供网站建设及推广服务。</p>
          <p>我们将致力于改变中小微企业与创业者原有的经营销售模式，帮扶中小微企业打开互联网营销局面，实现电子商务的梦想，我们将努力为企业打造全新的销售渠道并赚取丰厚的利润。</p>
          <p>我们坚信，中国的每一家企业都应该有一个赋有灵魂的营销平台，用来发布企业产品、传播企业文化、开展网上调查、与客户进行在线交流、分析客户需求和了解市场发展等功能于一体的营销型模式。</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default({
  data() {
    return {
      bannerIndex: 0
    }
  },
})
</script>


<style lang="scss" scoped>
.banner {
  .banner-item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 540px;
    img {
      position: absolute;
      left: 0;
      height: 0;
      z-index: -1;
      height: 540px;
      width: 100%;
    }
    span {
      font-size: 50px;
      color: #fff;
      font-weight: 600;
      margin-right: 40%;
    }
  }
}
.introduce-content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 70px 0;
  .content-title {
    color: #2b2b2b;
    font-size: 36px;
    text-align: center;
    margin-bottom: 16px;
  }
  .introduce-item {
    border-bottom: 1px solid #E6E6E6;
    display: flex;
    align-items: baseline;
    margin-bottom: 46px;
    font-size: 18px;
    text-align: left;
    .item-label {
      flex-grow: 0;
      flex-shrink: 0;
      width: 182px;
      color: #000;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      padding: 14px 0;
    }
    .item-text {
      color: #4A4A4A;
    }
  }
}
</style>